import { useEffect, useState } from "react"

function useWindowSize() {
    const [windowSize, setWindowSize] = useState<{
        width: number
        height: number
    }>({
        width: 0,
        height: 0,
    })

    useEffect(() => {
        if (typeof window === "undefined") return
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            })
        }

        window.addEventListener("resize", handleResize)
        handleResize()

        return () => window.removeEventListener("resize", handleResize)
    }, [])

    return {
        width: windowSize.width,
        height: windowSize.height,
        isMobile: windowSize.width < 768,
        isTabletOrSmaller: windowSize.width < 1024,
    }
}

export default useWindowSize
