import { useRouter } from "next/router"
import transEN from "locale/en.json"
import transAR from "locale/ar.json"
import * as Sentry from "@sentry/nextjs"

interface Resource {
    [key: string]: {
        translation: typeof transAR | typeof transEN
    }
}

export const useLang = () => {
    const router = useRouter()
    const { pathname, query, asPath } = router
    const { locale } = useRouter()

    const isProduction =
        process.env.NEXT_PUBLIC_FEATURE_ENVIRONMENT === "production"

    // TODO: refactor this to ltr and rtl instead of ar and en
    // set the language
    const isAr: boolean = locale === "ar-SA"
    const isEn: boolean = locale === "en-US"
    // check if the current language is arabic
    // if it is, return the arabic translation
    // if not, return the english translation
    const lang: string = isAr ? "ar" : "en"
    // translation files
    const resources: Resource = {
        ar: {
            translation: transAR,
        },
        en: {
            translation: transEN,
        },
    }
    function changeLanguage() {
        router.push({ pathname, query }, asPath, {
            // if the language is Arabic then change it to English
            locale: isAr ? "en-US" : "ar-SA",
        })
    }

    // custom hook replacing the old useTranslation from next-i18next
    const t = (key: string) => {
        const translation = resources[lang]?.translation[key]

        // run only on dev
        if (!isProduction && !translation) {
            console.log(key, "missing translation")
            Sentry.captureException(
                new Error(`Missing translation for key: [${key}, ${lang}]`)
            )
            return key
        }

        // If the translation key is missing, log an error to Sentry only in production environment
        if (isProduction && !translation) {
            // Return the key as a fallback if translation is missing
            return key
        }

        return translation
    }

    /**
     * this for translations with variables like {{ name }}
     *
     * @param key the key of the translation
     * @param vars the variables to be replaced
     * @returns the translated string
     * @example
     *
     */
    const dynamicTranslate = (key: string, vars: any) => {
        let translation = resources[lang]?.translation[key]

        // If the translation key is missing, log an error to Sentry only in production environment
        if (!isProduction && !translation) {
            // this also accounts for dev environment, as it is counted as production in the context of environement
            Sentry.captureException(
                new Error(`Missing translation for key: ${key}`)
            )

            // Return the key as a fallback if translation is missing
            return key
        }

        // Loop over each variable in the vars object
        Object.entries(vars).forEach(([varKey, varValue]) => {
            // Replace each occurrence of the variable within double curly braces
            const regex = new RegExp(`{{\\s*${varKey}\\s*}}`, "g")
            translation = translation?.replace(regex, varValue)
        })

        return translation
    }

    /**
     *
     *  if the variable comes in different language prefix it with the language
     * for example: if the language is Arabic then the variable will be prefixed with "ar_"
     * and if the language is English then the variable will be prefixed with "en_"
     * @returns
     * the variable with the language prefix
     * @example
     * multiLang("name") => "ar_name" or "en_name"
     *
     */

    const multiLangVars = (name: string) => {
        if (isAr) {
            return `ar_${name}`
        } else if (isEn) {
            return `en_${name}`
        } else {
            return name
        }
    }

    const SYSTEM_LANGUAGE = isAr ? "ar" : "en"
    /**
     *
     * @param obj {Object}
     * @param key {String}
     * takes an object that contains {language}_{key}
     * and @returns  {string} the value of the specified @key
     *
     */
    const getMultiLangValueByKey = <T extends NonNullable<Object>>(
        obj: T,
        requiredKey: string
    ) => {
        return Object.keys(obj)
            .map((target) => {
                const key = target as keyof T

                if (`${SYSTEM_LANGUAGE}_${requiredKey}` === target)
                    return obj[key]

                return null
            })
            .filter((val) => val !== null)
            .flat(1)
    }
    return {
        getMultiLangValueByKey,
        isAr,
        isEn,
        multiLangVars,
        t,
        lang,
        changeLanguage,
        dynamicTranslate,
    }
}
