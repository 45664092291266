import { useRouter } from "next/router"
import Script from "next/script"
import React from "react"

interface Props {
    children: React.ReactNode
}

const SnapchatPixelIntegration = ({ children }: Props) => {
    const router = useRouter()
    const currentEventId = router.pathname.includes("guvali")
        ? process.env.NEXT_PUBLIC_CURRENT_GUVALI_ID
        : (router?.query?.eventid as string)

    // List of events for which pixel is enabled
    const events = ["66eaa9f10c3c0a69b8ab51c9", currentEventId]

    // If pixel is not enabled for the current event, return the children directly
    if (!events.includes(currentEventId)) {
        return <>{children}</>
    }

    if (!currentEventId) {
        return <>{children}</>
    }

    const billoryPixelId = process.env.NEXT_PUBLIC_BILLORY_SNAPCHAT_PIXEL_KEY
    const guvaliPixelId = process.env.NEXT_PUBLIC_GUVALI_SNAPCHAT_PIXEL_KEY

    const pixelAccounts = {
        "66eaa9f10c3c0a69b8ab51c9": billoryPixelId,
        [currentEventId]: guvaliPixelId,
        "6343f62744f557215447e976": guvaliPixelId,
    }

    const pixelId = pixelAccounts[currentEventId]

    return (
        <>
            {pixelId && (
                <Script
                    id="snapchat-pixel"
                    async
                    defer
                    strategy="afterInteractive"
                    type="text/javascript"
                >
                    {`(function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
                    {a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
                    a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
                    r.src=n;var u=t.getElementsByTagName(s)[0];
                    u.parentNode.insertBefore(r,u);})(window,document,
                    'https://sc-static.net/scevent.min.js');
                    snaptr('init', '${pixelId}')`}
                </Script>
            )}

            {children}
        </>
    )
}

export default SnapchatPixelIntegration
