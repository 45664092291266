import React, { ReactNode, useEffect, useState } from "react"
import dynamic from "next/dynamic"
import VerifyAccModal from "components/Modal/VerifyAccModal"
import VerifyNotification from "components/VerifyNotification/VerifyNotification"
import { useUserContext } from "context/UserContext"
import { LazyMotion, domAnimation, m, AnimatePresence } from "framer-motion"
import useWindowSize from "hooks/useWindowSize"
import { useVerification } from "hooks/user/useVerification"
import { useShowNotification } from "hooks/user/useShowNotification"

const NewNavbar = dynamic(() => import("./NewNavbar"), {
    ssr: false,
    loading() {
        return (
            <div className="fixed top-0 z-50 h-[70px] w-full bg-white bg-opacity-50 shadow-3xl backdrop-blur-sm"></div>
        )
    },
})
const MobileMenu = dynamic(() => import("./MobileMenu"), {
    ssr: false,
})
const Footer = dynamic(() => import("components/Layout/Footer/Footer"), {
    ssr: false,
    loading: () => {
        return (
            <footer
                className={`fixed bottom-0 hidden h-[325px] flex-col overflow-hidden bg-white shadow-[0_0_9px_5px_rgba(0,0,0,0.05)] dark:bg-[#3A2B87] md:flex`}
            ></footer>
        )
    },
})
const FooterMobile = dynamic(
    () => import("components/Layout/FooterMobile/FooterMobile"),
    {
        ssr: false,
        loading: () => {
            return (
                <footer
                    className={`relative flex h-[580px] flex-col overflow-hidden bg-white shadow-[0_0_9px_5px_rgba(0,0,0,0.05)] dark:bg-[#3A2B87] md:hidden`}
                ></footer>
            )
        },
    }
)

interface IProps {
    children: ReactNode
    noAnimation?: boolean
}

const MainLayout = ({ children }: IProps) => {
    const [openModal, setOpenModal] = useState<boolean>(false)
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
    const { user } = useUserContext()
    const { isMobile } = useWindowSize()

    const { verifyCookie } = useVerification()
    const { showNotification, setShowNotification } = useShowNotification(
        user,
        verifyCookie
    )

    useEffect(() => {
        // disable page scroll when menu is open
        // and enable it when menu is closed or if the route changes
        if (isMenuOpen) {
            document.body.style.overflow = "hidden"
        } else {
            document.body.style.overflow = "auto"
        }

        return () => {
            document.body.style.overflow = "auto"
        }
    }, [isMenuOpen])

    return (
        <>
            <AnimatePresence>
                {isMenuOpen && <MobileMenu setIsMenuOpen={setIsMenuOpen} />}
            </AnimatePresence>

            {!verifyCookie && showNotification && (
                <VerifyNotification
                    setOpenModal={setOpenModal}
                    setShowNotification={setShowNotification}
                    isEmailVerified={user?.verification?.is_email_verified}
                    isPhoneVerified={user?.verification?.is_phone_verified}
                />
            )}

            <NewNavbar setIsMenuOpen={setIsMenuOpen} />

            {openModal && (
                <VerifyAccModal
                    setIsModalOpen={setOpenModal}
                    isModalOpen={openModal}
                    email={user?.contact?.email}
                    phone={user?.contact?.phone}
                    callingCode={user?.contact?.callingCode}
                    isEmailVerified={user?.verification?.is_email_verified}
                    isPhoneVerified={user?.verification?.is_phone_verified}
                />
            )}

            {/* <LazyMotion features={domAnimation}>
                <m.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.25 }}
                > */}
            {children}
            {/* </m.div> */}
            {/* // </LazyMotion> */}

            {isMobile ? <FooterMobile /> : <Footer />}
        </>
    )
}

export default MainLayout
