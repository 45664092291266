import { useLang } from "hooks/useLang"
import { ChangeEvent, useEffect, useRef, forwardRef } from "react"

interface IOtp {
    otp: string[]
    setOtp: (value: string[]) => void
    isFilled?: boolean
    isError?: boolean
    width?: string
    height?: string
    disabled?: boolean
}

type InputElement = HTMLInputElement | null

const Otp = forwardRef<HTMLInputElement, IOtp>(
    (
        {
            otp,
            setOtp,
            isFilled,
            isError,
            width = "w-14",
            height = "h-14",
            disabled,
        },
        ref
    ) => {
        const { t, isAr } = useLang()
        const inputsRef = useRef<InputElement[]>([])

        const handleChange = (
            e: ChangeEvent<HTMLInputElement>,
            index: number
        ) => {
            const val = e.target.value
            if (isNaN(Number(val))) return

            const newOtp = [...otp]
            newOtp[index] = val
            setOtp(newOtp)

            if (val) {
                focusNextInput(index)
            } else {
                focusPreviousInput(index)
            }
        }

        const focusNextInput = (currentIndex) => {
            if (inputsRef.current[currentIndex + 1]) {
                inputsRef.current[currentIndex + 1]?.focus()
            }
        }

        const focusPreviousInput = (currentIndex: number) => {
            const currentInput = inputsRef.current[currentIndex]
            if (currentIndex > 0 && currentInput && !currentInput.value) {
                inputsRef.current[currentIndex - 1]?.focus()
            }
        }

        useEffect(() => {
            if (inputsRef.current[0]) {
                inputsRef.current[0].focus()
            }
        }, [])

        return (
            <div>
                <div
                    className={`flex justify-center gap-x-2 ${isAr && "flex-row-reverse"}`}>
                    {otp.map((item, index) => (
                        <input
                            ref={(el) => {
                                inputsRef.current[index] = el
                            }}
                            className={`border bg-transparent text-center text-lg ${width} ${height} rounded-md ${
                                isError
                                    ? "errorStyle"
                                    : isFilled
                                      ? "border-green-500"
                                      : "verifyInput border-gray-200"
                            }`}
                            type="text"
                            name="otp"
                            maxLength={1}
                            key={index}
                            value={item || ""}
                            onChange={(e) => handleChange(e, index)}
                            onFocus={(e) => e.target.select()}
                            disabled={disabled}
                            inputMode="numeric"
                        />
                    ))}
                </div>
            </div>
        )
    }
)

Otp.displayName = "Otp"

export default Otp
